import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

const TermsAndConditionsData = {
    title: 'Terms and Conditions',
    description: 'Terms and conditions for Follow Your Senses in Tuscany'
};

export const TermsAndConditionsPageTemplate = () => (
    <section className="section">
        <div className="container">
            <div className="content">
                <h1>{TermsAndConditionsData.description}</h1>
                <p>
                    The entity providing online payment services is Blue Media
                    S.A. and Payu.pl Available forms of payment:
                </p>
                <p>Credit cards:</p>
                <li>Visa</li>
                <li>Visa Electron</li>
                <li>Mastercard</li>
                <li>MasterCardElectronic</li>
                <li>Maestro</li>
                <h2>Return/Refund:</h2>
                <li>
                    1. If it is necessary to return funds for a transaction made
                    by the customer with a payment card, the seller will refund
                    it to the bank account assigned to the customer's payment
                    card or the seller refunds payment using the same method of
                    payment as used by the consumer, unless the consumer has
                    expressly agreed to a different method of return that does
                    not involve any costs for him.
                </li>
                <li>
                    2. Order processing time is counted from the moment of
                    obtaining a positive payment authorization or If the
                    customer chooses the method of payment by bank transfer,
                    electronic payment or payment card - from the date of
                    crediting the account of the seller's bank account.
                </li>
                <li>
                    3. You can expect your refund to be acknowledged within 4
                    working days of receipt.
                </li>
                <li>
                    4. We will also notify you of the approval or rejection of
                    your refund. If you are approved, then your refund will be
                    processed, and a credit will automatically be applied to
                    your credit card or original method of payment, within 14
                    calendar days.
                </li>
                <li>
                    5. In addition to our Returns Policy, if you are a customer
                    in the European Union you have the right to cancel your
                    contract with us and receive a full refund within 14
                    calendar days from the day you made a payment. A consumer
                    who has concluded a distance contract or an off-premises
                    contract has the right to withdraw from it without giving
                    reasons within 14 days
                </li>
                <h2>Contact Info</h2>
                <p>Karolina Lenart - Follow Your Senses In Tuscany</p>
                <p>Ul. Nad Skawa 14</p>
                <p>34-200 Sucha Beskidzka</p>
                <p>Polska</p>
                <p>NIP: 5521645618</p>
                <p>Regon: 367184984</p>
                <p>+48880876413</p>
                <p>+393455809974</p>
            </div>
        </div>
    </section>
);

TermsAndConditionsPageTemplate.propTypes = {
    title: PropTypes.string.isRequired
};

const TermsAndConditionsPage = () => {
    return (
        <Layout>
            <SEO
                title={TermsAndConditionsData.title}
                description={TermsAndConditionsData.description}
            />
            <TermsAndConditionsPageTemplate
                title={TermsAndConditionsData.title}
            />
        </Layout>
    );
};

TermsAndConditionsPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default TermsAndConditionsPage;
